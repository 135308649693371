<template>
  <div class="order-status-form">
    <div class="header flex flex-row items-center py-4 justify-between">
      <h1><strong>Estatus de Orden</strong></h1>
      <sa-button type="primary" class="save-button" @click="save">Guardar</sa-button>
    </div>
    <div class="w-full flex flex-row justify-between">
      <div class="content flex flex-col space-y-4">
        <div>
            <label>Orden</label>
            <el-input v-model="status.order" type="number" min="0" placeholder="Orden" />
          </div>
        <div>
          <label>Nombre</label>
          <el-input v-model="status.name" placeholder="Nombre" />
        </div>
      </div>
      <div class="color-picker-container">
        <color-picker v-model="status.color" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.order-status-form {
  max-width: 800px;
  margin: 0 auto;
}
.header {
  border-bottom: 1px solid #ccc;
}
.header h1 {
  margin: 0;
}
.content {
  margin-top: 16px;
}
.color-picker-container {
  margin-top: 20px;
}
</style>
<script>
import { Sketch } from 'vue-color';

export default {
  name: 'OrderStatusForm',
  components: {
    'color-picker': Sketch,
  },
  props: {
    status: {
      type: Object,
      default: () => ({
        id: Number,
        name: String,
        color: String,
        order: Number,
      }),
    },
  },
  methods: {
    save() {
      const color = this.status.color?.hex || this.status.color;
      this.$emit('save', { ...this.status, color });
    },
  }
}
</script>